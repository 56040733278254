<template>
  <div style="background-color: #fafafa">
    <Myhead :nav="1" :nav3="1"></Myhead>
    <div class="header">
      <div class="header-text">
        <div class="header-title">{{ Language.certificateService.certificate_services }}</div>
        <div class="btn-contactUs" @click="tocontactUs">{{ Language.certificateService.Contact_Us }}</div>
      </div>
    </div>
    <div class="router">
      <div style="width: 1200px; margin: auto;">
        <router-link class="routerLink" to="/">{{ Language.certificateService.home }}</router-link> >
        <router-link class="routerLink" to="/our-services" >{{ Language.certificateService.Our_services }} ></router-link>
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer">&nbsp;{{ Language.certificateService.certificate_services }}</span>
      </div>
    </div>
    <div class="container">
      <div class="container-introduce">
        {{ Language.certificateService.msg1 }}
      </div>
      <div class="abstract">
        <div class="abstract-left">
          <div class="abstract-title">{{ Language.certificateService.Summary_of_Specific_Certification_Services }}：</div>
          <ul>
            <li>{{ Language.certificateService.msg2 }}</li>
            <li>{{ Language.certificateService.msg3 }}</li>
            <li>{{ Language.certificateService.msg4 }}</li>
            <li>{{ Language.certificateService.msg5 }}</li>
            <li>{{ Language.certificateService.msg6 }}</li>
          </ul>
        </div>
        <div class="abstract-img">
          <img src="../assets/img/certificateService/lab.png" alt />
        </div>
      </div>
      <div>
        <div class="certification-title">{{ Language.certificateService.Product_Certification }}</div>
        <!--中国-->
        <div class="country">
          <div class="country-title">{{ Language.certificateService.China }}</div>
          <div class="flex">
            <div class="country-img">
              <img class="imgStyle" src="../assets/img/certificateService/CHINA.jpg" alt />
            </div>
            <div class="rzitem">
              <div class="certification-name">{{ Language.certificateService.msg7 }}</div>
              <div class="imgbox">
                <img class="imgStyle" src="../assets/img/certificateService/CCC.jpg" alt />
              </div>
            </div>
            <div class="rzitem">
              <div class="certification-name">{{ Language.certificateService.msg8 }}</div>
              <div class="imgbox">
                <img class="imgStyle" src="../assets/img/certificateService/CQC.jpg" alt />
              </div>
            </div>
          </div>
        </div>
        <!--欧盟-->
        <div class="country">
          <div class="country-title">{{ Language.certificateService.European_Union }}</div>
          <div class="flex">
            <div class="country-img">
              <img class="imgStyle" src="../assets/img/certificateService/EU.jpg" alt />
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg9 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/CE.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg10 }}</div>
                <div style="width: 100%;">
                  <img class="imgStyle" src="../assets/img/certificateService/ROHS.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg11 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/GS.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg12 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/CB.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg13 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/UKCA.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg14 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/OKEO.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--美国-->
        <div class="country">
          <div class="country-title">{{ Language.certificateService.America }}</div>
          <div class="flex">
            <div class="country-img">
              <img class="imgStyle" src="../assets/img/certificateService/USA.jpg" alt />
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg15 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/FCC.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg16 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/CSA.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg17 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/UL.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg18 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/FDA.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg19 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/CPSC.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg20 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/ETL.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--亚洲-->
        <div class="country">
          <div class="country-title">{{ Language.certificateService.Asia }}</div>
          <div class="flex">
            <div class="country-img">
              <img class="imgStyle" src="../assets/img/certificateService/ASIAN.jpg" alt />
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg21 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/TIS.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg22 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/BIS.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg23 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/PSE.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg24 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/BPS.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg25 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/KC.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg26 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/SNI.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--其他-->
        <div class="country">
          <div class="country-title">{{ Language.certificateService.msg27 }}</div>
          <div class="flex">
            <div class="flex-column2">
              <div class="certification-name">{{ Language.certificateService.msg28 }}</div>
              <div class="imgbox">
                <img class="imgStyle" src="../assets/img/certificateService/COC.jpg" alt />
              </div>
            </div>

            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg30 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/INMETRO.jpg" alt />
                </div>
              </div>
              <div>
                <div class="certification-name">{{ Language.certificateService.msg33 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/NORIC.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <div>
                <div class="certification-name">{{ Language.certificateService.msg32 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/SAA.jpg" alt />
                </div>
              </div>

              <div>
                <div class="certification-name">{{ Language.certificateService.Multinational_certification }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/OTHER.jpg" alt />
                </div>
              </div>
            </div>
            <div class="flex-column2">
              <!--              <div>-->
              <!--                <div class="certification-name">{{ Language.certificateService.msg29 }}</div>-->
              <!--                <div class="imgbox">-->
              <!--                  <img class="imgStyle" src="../assets/img/certificateService/CB.jpg" alt />-->
              <!--                </div>-->
              <!--              </div>-->
              <div>
                <div class="certification-name">{{ Language.certificateService.msg31 }}</div>
                <div class="imgbox">
                  <img class="imgStyle" src="../assets/img/certificateService/SABER.jpg" alt />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";
export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components:{
    Myhead,
    Myfoot
  },
  mounted() {
    this.getdata()
  },
  methods:{
    getdata(){
      this.$get("/m/indexset/knowledge", {
        tag:'certification',
        pageNo:-1
      }).then(res => {
        console.log(res)
      })
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-top: -70.5px;
  width: 100%;
  height: 360px;
  color: #FFFFFF;
  background-image: url("../assets/img/certificateService/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header-text {
    max-width: 1200px;
    margin: auto;
    padding: 0px 20px;
    padding-top: 150px;

    .header-title {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
}

.router {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;
  padding: 0px 20px;
}

.container {
  max-width: 1200px;
  margin: auto;
  //padding: 0 360px;
  color: var(--title-color);
  .flex{
    .flex-column2{

    }
  }
  .container-introduce {
    margin: 80px 0;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .abstract {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 160px;

    .abstract-left{
      width: calc(100% - 480px);
      margin-right: 20px;
    }

    .abstract-title {
      font-size: 42px;
    }

    ul {
      padding-inline-start: 10px;
      color: var(--text-color);

      li {
        margin-bottom: 8px;
      }
    }

    .abstract-img {
      width: 460px;
      height: 260px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.certification-title {
  font-size: 42px;
  color: var(--title-color);
  text-align: center;
  margin-bottom: 40px;
}

.certification-name {
  color: #000000;
  font-size: 20px;
  height: 42px;
  line-height: 42px;
  background-color: #f5f5f5;
  padding-left: 8px;
}

.country {
  margin-bottom: 80px;

  .country-title {
    font-size: 30px;
    color: var(--text-color);
    margin-bottom: 16px;
  }

  .country-img {
    width: 290px;
    height: 360px;
  }
}
@media (min-width: 0px) and (max-width: 1200px){
    .container{
      .container-introduce{
        padding: 0px 20px;
      }
      .abstract{
        flex-wrap: wrap;
        margin-bottom: 30px;
        .abstract-left{
          width: 100%;
          padding: 0px 20px;
        }
        .abstract-img{
          width: 100%;
          height: auto;
        }
      }

    }

  .country{
    .country-title{
      padding: 0px 20px;
    }
    .flex{
      flex-wrap: wrap;
      padding: 0px 20px;
      .flex-column2{
        width: 48%;
      }
      .rzitem{
        width: 50%;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 620px){
  .country{
    .country-title{
      padding: 0px 20px;
    }
    .flex{
      flex-wrap: wrap;
      padding: 0px 20px;
      .flex-column2{
        width: 100%;
      }
      .rzitem{
        width: 100%;
      }
    }
  }
}
</style>
